/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { useState, useContext, useEffect } from "react"
// eslint-disable-next-line import/no-cycle
import InputContainer from "./InputContainer"
import FormContext from "../../contexts/FormContext"
import { mapValues } from "../../utils"
import styles from "./CIAward.module.scss"

const CIAward = ({ id, field, text }) => {
  const [checked, setChecked] = useState()
  const { pageData, clearErrors, watch } = useContext(FormContext)
  const teamSize = watch(field.teamId)
  const showTeam = checked && (teamSize > 1 || teamSize === "more")

  useEffect(() => {
    if (!checked || teamSize <= 1) {
      clearErrors("ciAwardTeam")
    }
  }, [checked, teamSize])

  return (
    <>
      <InputContainer
        id={`${id}-ciAward`}
        field={field.ciAward}
        text={text.ciAward}
        onChange={e => setChecked(e.currentTarget.checked)}
      />
      {showTeam && (
        <fieldset aria-describedby={`${id}-description`}>
          <div
            id={`${id}-description`}
            dangerouslySetInnerHTML={{
              __html: pageData.step_5_hidden_description.html,
            }}
          />
          <table className={styles.table}>
            <tbody>
              {
                // team members (excluding applicant) or five rows,
                // whichever is smaller
                new Array(
                  Math.min((teamSize === "more" ? 26 : teamSize) - 1, 5)
                )
                  .fill("")
                  .map((_, i) => (
                    <tr key={i} className={styles.row}>
                      <td className={styles.bold}>{i + 1}</td>
                      {field.teamFields.map(tField => (
                        <td key={tField.id}>
                          <InputContainer
                            id={`${id}-${i}-${tField.id}`}
                            field={mapValues(
                              tField,
                              (key, val) => val.replace("NUMBER", i + 1),
                              // send team info as an array of objects
                              { id: `ciAwardTeam[${i}].${tField.id}` }
                            )}
                            text={mapValues(
                              text.teamFields[tField.id],
                              (key, val) => val.replace("NUMBER", i + 1)
                            )}
                          />
                        </td>
                      ))}
                    </tr>
                  ))
              }
            </tbody>
          </table>
        </fieldset>
      )}
    </>
  )
}

export default CIAward
