/* eslint-disable react/no-danger */
import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
// explicitly declare IE11 support (also works in IE9/10)
import { useForm } from "react-hook-form/dist/index.ie11"
import { FaExclamationCircle } from "react-icons/fa"
import { withPreview } from "gatsby-source-prismic"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import styles from "../scss/apply.module.scss"
import useAmountTemplate from "../hooks/use-amount-template"
import useReferrerCookie from "../hooks/use-referrer-cookie"
import useIEVersion from "../hooks/use-ie-version"
import FormSchema from "../data/apply.form.yml"
import InputContainer from "../components/Form/InputContainer"
import FormContext from "../contexts/FormContext"
import useAnalyticsEvent from "../hooks/use-analytics-event"

const FormContent =
  process.env.GATSBY_BUILD_LANG !== "fr"
    ? require("../data/apply.en.yml")
    : require("../data/apply.fr.yml")

const ApplyPage = ({ data }) => {
  const pageData = data.allPrismicGrantForm.nodes[0].data
  const { register, handleSubmit, clearErrors, errors, watch } = useForm()
  const [submitting, setSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const isIE = useIEVersion()
  // const PTFNMI = watch(["underrepresented", "pt"])
  // const isPTFNMI =
  //   PTFNMI?.underrepresented?.includes("IN") ||
  //   ["YT", "NU", "NT"].includes(PTFNMI?.pt)
  const flattenErrors = (id, errorObj) => {
    if (!errorObj.ref) {
      if (Array.isArray(errorObj)) {
        // flattened array
        return errorObj.reduce(
          (arr, err, i) => arr.concat(flattenErrors(`${id}[${i}]`, err)),
          []
        )
      }
      // is object
      return Object.entries(errorObj).reduce(
        (arr, [key, err]) => arr.concat(flattenErrors(`${id}.${key}`, err)),
        []
      )
    }
    return [{ id, ...errorObj }]
  }
  const errorArr = Object.entries(errors)
    .reduce((arr, [id, val]) => arr.concat(flattenErrors(id, val)), [])
    .map((err, i) => ({ ...err, number: i + 1 }))

  const trackSubmitEvent = useAnalyticsEvent(
    "application",
    "Application Form",
    "submit",
    "RisingYouth"
  )
  const submit = async formData => {
    setSubmitting(true)
    // async map values
    const body = {}
    // upload files and save filenames
    try {
      console.log(formData)
      await Promise.all(
        Object.entries(formData).map(async ([key, val]) => {
          if (val.toString() === "[object FileList]") {
            if (val.length === 0) return
            const file = val[0]
            const res = await fetch(
              `https://us-central1-risingyouth-5fe76.cloudfunctions.net/upload`,
              {
                method: "POST",
                headers: {
                  "Content-type": file.type,
                },
                body: file,
              }
            )
            const filename = await res.text()
            body[key] = filename
          } else {
            body[key] = val
          }
        })
      )
    } catch (e) {
      setSubmitError(`on upload: ${e.message}`)
      setSubmitting(false)
      return
    }
    // transform schema
    body.budget = body.budgetFile ? body.budgetFile : body.budgetList
    delete body.budgetFile
    delete body.budgetList
    // boolean dropdowns -> actual booleans
    body.covidResponse = body.covidResponse === "t"
    body.prevVolunteer = body.prevVolunteer === "t"
    body.communications = body.communications === "t"
    body.referrerID = useReferrerCookie("hostreferAirtableID") || ""
    body.browser = navigator.userAgent
    body.originURL = window.location.href
    // submit
    try {
      const res = await fetch(
        useAmountTemplate(
          `https://us-central1-risingyouth-5fe76.cloudfunctions.net/apply/LEVEL`
        ),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      )
      const json = await res.json()
      if (json.error) {
        throw json.error
      }
      setSubmitError(false)
      trackSubmitEvent()
      navigate("/thanks")
    } catch (e) {
      setSubmitError(`
      ${
        isIE &&
        `If you are using Internet Explorer, please try using Google Chrome or Firefox to submit this form. \n`
      }
      on POST: ${e.message}
      `)
    }
    setSubmitting(false)
  }

  return (
    <FormContext.Provider
      value={{
        register,
        pageData,
        errors: errorArr,
        clearErrors,
        watch,
        global: FormContent.global,
      }}
    >
      <Layout submitting={submitting} showHeader={false}>
        <SEO title={useAmountTemplate(pageData.title.text)} />
        <header className={styles.header}>
          <div className="container">
            <div className="row">
              <div className={`${styles.brand} columns small-9 medium-6`}>
                <a href={pageData.logo_link.url}>
                  <Img
                    style={{
                      width: `${pageData.tig_logo.fluid.aspectRatio * 100}px`,
                    }}
                    fluid={pageData.tig_logo.fluid}
                    alt={pageData.tig_logo.alt}
                  />
                </a>
              </div>
            </div>
          </div>
        </header>
        <main className={`${styles.container} container`}>
          <div className="row">
            <h1>{useAmountTemplate(pageData.heading.text)}</h1>
            {isIE && isIE <= 11 && (
              <div className="callout warning" role="alert">
                <h2>
                  <FaExclamationCircle role="presentation" />{" "}
                  {FormContent.global.browserWarning}
                </h2>
                <p>{FormContent.global.browserInstructions}</p>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: useAmountTemplate(pageData.subtitle.html),
              }}
            />
            {/* if there's no referrer, show the referrer alert */}
            {!useReferrerCookie("hostrefer") && pageData?.grant_alert?.html && (
              <div
                className="callout"
                dangerouslySetInnerHTML={{ __html: pageData.grant_alert.html }}
              />
            )}
            <form
              autoComplete="off"
              noValidate="novalidate"
              onSubmit={handleSubmit(submit)}
              disabled={submitting}
            >
              {errorArr.length > 0 && (
                <div className="callout alert">
                  <h2>
                    <FaExclamationCircle role="presentation" />{" "}
                    {errorArr.length === 1
                      ? FormContent.global.errorSingular
                      : FormContent.global.errorPlural.replace(
                          "NUMBER",
                          errorArr.length
                        )}
                  </h2>
                  <ul>
                    {errorArr.map(error => {
                      const { message, number, ref } = error
                      return (
                        <li key={number}>
                          <a href={`#${ref.getAttribute("id")}-error`}>
                            {FormContent.global.error.replace("NUMBER", number)}
                            {message}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
              {FormSchema.sections.map(section => {
                if (section.id === "example") return null
                return (
                  <fieldset
                    key={section.id}
                    id={section.id}
                    className={styles.step}
                  >
                    <h2>
                      <legend>{pageData[`${section.id}_heading`]?.text}</legend>
                    </h2>
                    <aside
                      className={styles.description}
                      dangerouslySetInnerHTML={{
                        __html: useAmountTemplate(
                          pageData[`${section.id}_description`]?.html
                        ),
                      }}
                    />
                    {section.fields.map((field, i, arr) => {
                      const text =
                        FormContent.sections[section.id].fields[field.id]
                      if (!text)
                        return `Missing i18n: ${section.id}/${field.id}`

                      return (
                        <>
                          {/* if they're underrep, show grant underrep alert inline */}
                          {/* isPTFNMI &&
                            pageData?.grant_underrep?.html &&
                            ["pt", "underrepresented"].includes(field.id) && (
                              <div
                                className="callout"
                                dangerouslySetInnerHTML={{
                                  __html: pageData?.grant_underrep.html,
                                }}
                              />
                              ) */}
                          <InputContainer
                            key={field.id}
                            id={`${section.id}-${field.id}`}
                            single={arr.length === 1}
                            field={{
                              ...field,
                              ...(field.type === "cssg" && {
                                type: "checkbox",
                              }),
                            }}
                            text={{
                              ...text,
                              ...(field.type === "cssg" && {
                                description: pageData.step_6_cssg.html,
                              }),
                            }}
                          />
                        </>
                      )
                    })}
                  </fieldset>
                )
              })}
            </form>
            {submitError && (
              <div className="callout alert">
                <h2>{FormContent.global.errorSubmit}</h2>
                <p>{submitError}</p>
              </div>
            )}
          </div>
        </main>
      </Layout>
    </FormContext.Provider>
  )
}

export const query = graphql`
  {
    allPrismicGrantForm {
      nodes {
        prismicId
        data {
          form_action {
            url
          }
          heading {
            text
          }
          subtitle {
            html
          }
          grant_alert {
            html
          }
          grant_underrep {
            html
          }
          tig_logo {
            fluid(maxWidth: 2000) {
              ...GatsbyPrismicImageFluid
              aspectRatio
            }
            alt
          }
          title {
            text
          }
          logo_link {
            url
          }
          step_2_description {
            html
          }
          step_2_heading {
            text
          }
          step_3_description {
            html
          }
          step_3_heading {
            text
          }
          step_4_description {
            html
          }
          step_4_heading {
            text
          }
          step_5_description {
            html
          }
          step_5_heading {
            text
          }
          step_5_hidden_description {
            html
          }
          step_6_description {
            html
          }
          step_6_heading {
            text
          }
          step_6_cssg {
            html
          }
        }
      }
    }
  }
`

export default withPreview(ApplyPage)
