/**
 * Like Array.map but for objects
 * @param {object} obj Object to map
 * @param {(key: string, value: object) => object} callback Execute on k-v pair
 * @param {object} overrides Add/override additional key-value pairs
 * @returns {object}
 */
export const mapValues = (obj, callback, overrides = {}) =>
  Object.entries(obj).reduce(
    (o, [k, v]) => ({ [k]: callback(k, v), ...o }),
    overrides
  )

/**
 * "Polyfill" for `String.prototype.normalize` (by ignoring normalization)
 * @param {string} str String to normalize
 */
const normalize = str =>
  String.prototype.normalize ? str.normalize("NFD") : str

/**
 * Sort function that respects diacritics
 */
export const diacriticSort = (a, b) => {
  const strA = normalize(a)
  const strB = normalize(b)
  return strA < strB ? -1 : strA > strB
}

/**
 * Test whether a number is outside given bounds
 * For standard prop usage, call as minMax(field, value, text)
 * @param {{ min: Object, max: Object }} bounds Min/max
 * @param {string} value The value to test
 * @param {{ min: *, max: *, pass: * }} returns Values to return
 */
export const minMax = (bounds, value, returns = {}) => {
  const { min, max } = bounds
  const num = Number.parseFloat(value)
  // allow 0 value on if statements
  if (min != null && num < min) {
    return returns.min || false
  }
  if (max != null && num > max) {
    return returns.max || false
  }
  return returns.pass || true
}

/**
 * Test whether a date is outside given bounds with respect to today
 * For standard prop usage, call as dateMinMax(field, value, text)
 * @param {{ min: Object, max: Object }} bounds Min/max
 * @param {string} value The date to test
 * @param {{ min: *, max: *, pass: * }} returns Values to return
 */
export const dateMinMax = (bounds, value, returns = {}) => {
  const { min, max } = bounds
  let date = new Date(value) // will be in UTC
  if (max) {
    const now = new Date()
    const maxDate = new Date(
      now.setUTCFullYear(
        // use local time to set y/m/d and compare in utc since date is in utc
        max.year ? now.getFullYear() + max.year : now.getFullYear(),
        max.month ? now.getMonth() + max.month : now.getMonth(),
        max.date ? now.getDate() + max.date : now.getDate()
      )
    ).setUTCHours(0, 0, 0, 0)
    if (date >= maxDate) return returns.max || false
  }
  // when type = month, pick last day of month for testing min
  if (/^\d{4}-\d{2}$/.test(value)) {
    date = new Date(
      new Date(
        new Date().setUTCFullYear(
          date.getUTCFullYear(),
          date.getUTCMonth() + 1,
          0
        )
      ).setUTCHours(0, 0, 0, 0)
    )
  }
  if (min) {
    const now = new Date()
    const minDate = new Date(
      now.setUTCFullYear(
        min.year ? now.getFullYear() + min.year : now.getFullYear(),
        min.month ? now.getMonth() + min.month : now.getMonth(),
        min.date ? now.getDate() + min.date : now.getDate()
      )
    ).setUTCHours(0, 0, 0, 0)
    if (date <= minDate) return returns.min || false
  }
  return returns.pass || true
}
