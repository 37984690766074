import React, { useContext, useState, useEffect } from "react"
// eslint-disable-next-line import/no-cycle
import InputContainer from "./InputContainer"
import FormContext from "../../contexts/FormContext"
import { dateMinMax } from "../../utils"

const ProvDOB = ({ id, field, text }) => {
  const { watch, errors, clearErrors } = useContext(FormContext)
  const [dob, setDob] = useState("")
  const { [field.triggerId]: watchTrigger, pgEmail, pgPhone } = watch([
    field.triggerId,
    field.email.id,
    field.phone.id,
  ])
  const triggerConstraint = field.triggerValues.find(
    trigger => trigger.value === "*" || trigger.value.includes(watchTrigger)
  )
  const showPGFields = !dateMinMax(triggerConstraint, dob)

  useEffect(() => {
    // clear all pg errors if fields are gone
    if (!showPGFields)
      clearErrors([field.name.id, field.phone.id, field.email.id])
    // clear pgEmail/Phone required errors when requirement is unset
    if (
      pgEmail &&
      errors.find(e => e.id === field.phone.id)?.type === "required"
    )
      clearErrors(field.phone.id)
    if (
      pgPhone &&
      errors.find(e => e.id === field.email.id)?.type === "required"
    )
      clearErrors(field.email.id)
  }, [showPGFields, pgEmail, pgPhone])

  return (
    <>
      <InputContainer
        id={`${id}-dob`}
        field={field.dateOfBirth}
        text={text.dateOfBirth}
        onChange={e => setDob(e.currentTarget.value)}
      />
      {showPGFields && (
        <>
          <InputContainer
            id={`${id}-name`}
            field={field.name}
            text={text.name}
            required={pgEmail}
          />
          <InputContainer
            id={`${id}-email`}
            field={field.email}
            text={text.email}
            required={!pgPhone}
          />
          <InputContainer
            id={`${id}-phone`}
            field={field.phone}
            text={text.phone}
            required={!pgEmail}
          />
          <InputContainer
            id={`${id}-relationship`}
            field={field.relationship}
            text={text.relationship}
            required
          />
        </>
      )}
    </>
  )
}

export default ProvDOB
