import React from "react"
// eslint-disable-next-line import/no-cycle
import { Select } from "./InputContainer"

const TeamSize = ({ id, field, text, ...props }) => {
  // empty array of 2..24
  const arr = new Array(24).fill(0).map((_, i) => `${i + 2}`)
  const options = ["1", ...arr, "more"]
  const textOptions = {
    "1": text.none,
    ...arr.reduce((obj, key) => ({ ...obj, [key]: `${key - 1}` }), {}),
    more: text.more,
  }
  return (
    <Select
      id={id}
      field={{ ...field, options }}
      text={{ ...text, options: textOptions }}
      {...props}
    />
  )
}

export default TeamSize
