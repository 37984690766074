/* eslint-disable import/no-cycle */
import React, { useState, useContext, useEffect } from "react"
import InputContainer from "./InputContainer"
import useReferrerCookie from "../../hooks/use-referrer-cookie"
import { mapValues } from "../../utils"
import FormContext from "../../contexts/FormContext"

const Referral = ({ id, field, text }) => {
  const { clearErrors } = useContext(FormContext)
  const referrer = useReferrerCookie("hostrefer") || ""
  const eventReferrer = useReferrerCookie("eventrefer") || ""
  // prioritize event referrals, then partner org
  const formValue = eventReferrer ? "event" : (referrer ? "org" : "")
  const whichValue = eventReferrer ? eventReferrer : (referrer ? referrer : "")
  const [value, setValue] = useState(formValue)
  // must be explicitly false and not "" since <tag attr="">
  // resolves to <tag attr> and not <tag>
  const which = value !== "" && field.which.trigger.includes(value)
  useEffect(() => {
    if (!field.which.trigger.includes(value)) clearErrors(field.which.id)
  }, [value])
  return (
    <>
      <InputContainer
        id={id}
        field={field.referral}
        text={text.referral}
        onChange={e => setValue(e.currentTarget.value)}
        style={{ marginBottom: !which && 0 }}
        defaultValue={formValue}
      />
      <InputContainer
        id={`${id}-referrer`}
        field={field.which}
        text={mapValues(
          text.which,
          (key, val) => {
            const t = text.referral.options[value]
            return val.replace(
              "TYPE",
              t ? t.charAt(0).toLowerCase() + t.slice(1) : ""
            )
          },
          {}
        )}
        // required={which}
        defaultValue={whichValue}
        className={!which && "hide"}
      />
    </>
  )
}

export default Referral
