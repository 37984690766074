import React, { useState, useEffect, useContext } from "react"
// eslint-disable-next-line import/no-cycle
import InputContainer from "./InputContainer"
import FormContext from "../../contexts/FormContext"
import useAmountTemplate from "../../hooks/use-amount-template"

const Budget = ({ id, field, text: rawText }) => {
  const { clearErrors } = useContext(FormContext)
  const [help, setHelp] = useState(false)
  const [fileEmpty, setFileEmpty] = useState(true)
  const [listEmpty, setListEmpty] = useState(true)
  const amount = useAmountTemplate("LEVEL")
  // honorarium is dynamic and cannot be generated using formula,
  // hard-coded in apply.form.yml at maxHonorarium[amount]
  const text = JSON.parse(
    // easier than deep mapping object values (since it's guaranteed to
    // have only strings anyways)
    JSON.stringify(rawText).replace(
      /MAX_HONORARIUM/g,
      field.maxHonorarium[amount]
    )
  )

  useEffect(() => {
    if (help || !listEmpty) clearErrors(field.file.id)
    if (help || !fileEmpty) clearErrors(field.list.id)
  }, [help, fileEmpty, listEmpty])

  return (
    <>
      <InputContainer
        id={`${id}-file`}
        field={field.file}
        text={text.file}
        required={!help && listEmpty}
        onChange={e => setFileEmpty(e.currentTarget.value === "")}
      />
      {/* This will never be marked as required due to missing custom logic for
          the validator, however, because file ONLY throws errors when list is
          also empty, the necessary logic is already there.
          It's not a bug, it's a feature. */}
      <InputContainer
        id={`${id}-list`}
        field={field.list}
        text={text.list}
        required={!help && fileEmpty}
        onChange={e =>
          setListEmpty(e.currentTarget.value.every(val => val === ""))
        }
      />
      <InputContainer
        id={`${id}-help`}
        field={field.budgetHelp}
        text={text.budgetHelp}
        onChange={e => setHelp(e.currentTarget.checked)}
      />
    </>
  )
}

export default Budget
