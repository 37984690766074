/**
 * Push a GTM custom event and a Facebook Pixel custom event at once
 * @param {string} eventName Name of the event
 * @param {string} category The object that was interacted with
 * @param {string} action The type of interaction
 * @param {string} label Event categorization
 * @returns {() => void} Function which fires the event
 */
const useAnalyticsEvent = (eventName, category, action, label) => () => {
  if (typeof window === "undefined") return
  // google tag manager
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: eventName, category, action, label })
  // facebook pixel
  if (window.fbq) {
    window.fbq("customEvent", eventName, { category, action, label })
  }
}

export default useAnalyticsEvent
