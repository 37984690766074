/* eslint-disable react/no-danger */
import React, { useContext, useState, useRef, useEffect } from "react"
import cx from "classnames"
/* eslint-disable import/no-cycle */
import ReactMarkdown from "react-markdown"
import UnderRepresented from "./Underrepresented"
import Referral from "./Referral"
import CIAward from "./CIAward"
import Timing from "./Timing"
import Budget from "./Budget"
import TeamSize from "./TeamSize"
import ProvDOB from "./ProvDOB"
/* eslint-enable import/no-cycle */
import FormContext from "../../contexts/FormContext"
import styles from "../../scss/apply.module.scss"
import { diacriticSort, dateMinMax, minMax } from "../../utils"
import useIEVersion from "../../hooks/use-ie-version"
import useAmountTemplate from "../../hooks/use-amount-template"
import useReferrerCookie from "../../hooks/use-referrer-cookie"

export const makeRules = props => {
  const { global } = useContext(FormContext)
  const { field, text, validate, required, pattern, minLength } = props
  const out = {}

  out.required = {
    value: false,
    message: text.required || global.required,
  }
  const requiredProp = required || field.required
  if (requiredProp === true) {
    out.required.value = true
  } else if (typeof requiredProp === "object") {
    if (requiredProp.trigger === "amount") {
      const amount = useAmountTemplate("LEVEL")
      out.required.value = minMax(requiredProp, amount)
    } else {
      // TODO: implement watching
    }
  }

  out.validate = value => {
    if (validate) return validate(value)
    if (value) {
      // not empty + failing regex = error
      const p =
        // first try regex parameter
        pattern ||
        // then try yaml field
        field.pattern ||
        // before falling back on this horror
        (field.type === "email" &&
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ||
        (field.type === "tel" &&
          /^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}.*/)
      // clean spaces for phone numbers
      const str = field.type === "tel" ? value.replace(/\s+/g, "") : value
      if (p) {
        // convert to regex at last moment for IE9 reasons
        const regex = typeof p === "string" ? new RegExp(p) : p
        if (!regex.test(str)) return text.pattern || global[field.type]
      }
    }
    return true
  }

  out.minLength = {
    value: field.minLength || minLength || 0,
    message: global.minLength.replace("NUMBER", field.minLength),
  }

  return out
}

export const GenericInput = props => {
  const { id, field, text, required, pattern, validate, rules, ...more } = props
  const { errors, register } = useContext(FormContext)
  const error = errors.find(e => e.id === field.id)

  const override =
    field.type === "submit" ? { ...more, className: "button primary" } : more

  return (
    <input
      id={id}
      name={field.id}
      aria-labelledby={`${id}-label`}
      className={styles.textInput}
      type={field.type}
      required={rules.required.value}
      aria-required={rules.required.value}
      aria-invalid={!!error}
      placeholder={text.placeholder}
      size={60}
      maxLength={255}
      ref={field.type !== "submit" ? register(rules) : undefined}
      value={field.type === "submit" ? text.text : undefined}
      {...override}
      {...field.props}
    />
  )
}

export const DateInput = props => {
  const { field, text, rules } = props
  const { global } = useContext(FormContext)

  // test for <input type=date> support
  if (typeof document !== "object") return null
  const testElement = document.createElement("input")
  testElement.setAttribute("type", field.type)
  testElement.setAttribute("value", "black magic")
  const dateSupported = testElement.value !== "black magic"
  if (dateSupported) {
    return (
      <GenericInput
        {...props}
        rules={{ ...rules, validate: value => dateMinMax(field, value, text) }}
      />
    )
  }

  // fallback on regex + text field
  return (
    <GenericInput
      {...props}
      field={{
        ...field,
        type: "text",
      }}
      text={{
        ...text,
        placeholder: global.placeholder[field.type],
      }}
      rules={{
        ...rules,
        validate: value => {
          const regexes = {
            date: /\d{4}-\d{2}-\d{2}/,
            month: /\d{4}-\d{2}/,
          }
          // eslint-disable-next-line no-restricted-globals
          if (!regexes[field.type].test(value) || isNaN(new Date(value))) {
            return global[field.type]
          }
          return dateMinMax(field, value, text)
        },
      }}
    />
  )
}

/**
 * Repeated `GenericInput`
 */
export const MultiInput = ({ id, field, text, onChange }) => {
  const [num, setNum] = useState(field.num)
  const values = useRef(new Array(num).fill(""))
  return (
    <>
      {new Array(num).fill("").map((_, i) => (
        <GenericInput
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          id={`${id}-${i}`}
          field={{ ...field.repeat, id: `${field.id}[${i}]` }}
          text={text.repeat}
          onChange={e => {
            values.current[i] = e.currentTarget.value
            // make a fake event to propagate up
            if (onChange) {
              const evt = { currentTarget: { value: values.current } }
              onChange(evt)
            }
          }}
          rules={makeRules({ field: field.repeat, text: text.repeat })}
        />
      ))}
      {field.canAdd && (
        <button
          className="button button-small"
          type="button"
          onClick={e => {
            e.preventDefault()
            setNum(num + 1)
          }}
        >
          + Add Line
        </button>
      )}
    </>
  )
}

export const Select = ({ id, field, text, required, rules, ...props }) => {
  const { global, errors, register } = useContext(FormContext)
  const error = errors.find(e => e.id === field.id)
  const options = field.sort
    ? field.options.sort((a, b) =>
        diacriticSort(text.options[a], text.options[b])
      )
    : field.options
  return (
    <select
      id={id}
      name={field.id}
      aria-labelledby={`${id}-label`}
      className={styles.select}
      required={rules.required.value}
      aria-required={rules.required.value}
      aria-invalid={!!error}
      ref={register(rules)}
      defaultValue=""
      {...props}
    >
      <option hidden disabled value="">
        {global.select}
      </option>
      {options.map(option => {
        return (
          <option key={option} value={option}>
            {text.options[option]}
          </option>
        )
      })}
    </select>
  )
}
export const BooleanSelect = ({ field, text, ...props }) => {
  const { global } = useContext(FormContext)
  return (
    <Select
      {...props}
      field={{ ...field, options: ["t", "f"] }}
      text={{ ...text, options: global.boolean }}
    />
  )
}

export const MultiCheckBox = ({ id, field, text, onChange, rules }) => {
  const { register } = useContext(FormContext)
  return field.options.map(option => (
    <label
      key={option}
      id={`${id}-${option}-label`}
      className={styles.multiCheckbox}
      htmlFor={`${id}-${option}`}
    >
      <input
        id={`${id}-${option}`}
        name={field.id}
        aria-labelledby={`${id}-${option}-label`}
        type="checkbox"
        value={option}
        ref={register(rules)}
        onChange={e => onChange && onChange(e, option)}
      />
      {text.options[option]}
    </label>
  ))
}

export const CheckBox = ({ id, field, text, rules, ...props }) => {
  const { errors, register } = useContext(FormContext)
  const error = errors.find(e => e.id === field.id)
  return (
    <input
      id={id}
      name={field.id}
      className={styles.checkbox}
      type="checkbox"
      required={rules.required.value}
      aria-required={rules.required.value}
      aria-invalid={!!error}
      ref={register(rules)}
      {...props}
    />
  )
}

export const Radio = ({ id, field, text, rules }) => {
  const { register } = useContext(FormContext)
  return field.options.map(option => (
    <label
      key={option}
      id={`${id}-label`}
      className={styles.multiCheckbox}
      htmlFor={`${id}-${option}`}
    >
      <input
        id={`${id}-${option}`}
        aria-labelledby={`${id}-label`}
        name={field.id}
        type="radio"
        value={option}
        ref={register(rules)}
      />
      {text.options[option]}
    </label>
  ))
}

export const TextArea = ({ id, field, text, rules, ...props }) => {
  const { errors, register } = useContext(FormContext)
  const error = errors.find(e => e.id === field.id)
  return (
    <textarea
      id={id}
      name={field.id}
      aria-labelledby={`${id}-label`}
      type={field.type}
      required={rules.required.value}
      aria-required={rules.required.value}
      aria-invalid={!!error}
      placeholder={text.placeholder}
      cols={60}
      rows={3}
      ref={register(rules)}
      {...props}
    />
  )
}

/**
 * Map of fieldTypes to renderable elements
 * @type {Object<string, React.ElementType>}
 */
const fieldTypes = {
  text: GenericInput,
  email: GenericInput,
  tel: GenericInput,
  file: GenericInput,
  date: DateInput,
  month: DateInput,
  select: Select,
  checkbox: CheckBox,
  radio: Radio,
  textarea: TextArea,
  // custom
  submit: GenericInput,
  boolSelect: BooleanSelect,
  multi: MultiInput,
  multicheckbox: MultiCheckBox,
  teamSize: TeamSize,
  // higher order
  provDob: ProvDOB,
  underrepresented: UnderRepresented,
  referral: Referral,
  ciAward: CIAward,
  timing: Timing,
  budget: Budget,
}

/**
 * Wrapper for labelled form inputs
 * @param {Object} props JSX properties
 * @param {React.CSSProperties} props.style Styling on container element
 * @param {boolean} props.single Is this the only child of a `<fieldset>`?
 */
const InputContainer = ({ style, single, className, ...props }) => {
  const { id, field, text } = props
  const { global, errors } = useContext(FormContext)
  const error = errors.find(e => e.id === field.id)

  let displayField = true

  if (field.triggerCookies) {
    let cookieFound = false
    field.triggerCookies.forEach(cookie => {
      if (useReferrerCookie(cookie)) {
        cookieFound = true
      }
    })

    if (!cookieFound) {
      displayField = false
    }
  }

  const tagRef = useRef()
  const asideRef = useRef()
  const ie = useIEVersion()
  const needsHoverPolyfill = ie && ie <= 9
  useEffect(() => {
    if (needsHoverPolyfill && asideRef.current) {
      // don't use state to avoid many re-renders
      // (since IE already has atrocious performance)
      tagRef.current.addEventListener("mouseenter", () => {
        asideRef.current.style.visibility = "visible"
        asideRef.current.style.opacity = 1
      })
      tagRef.current.addEventListener("mouseleave", () => {
        asideRef.current.style.visibility = "hidden"
        asideRef.current.style.opacity = 0
      })
    }
  }, [tagRef.current, asideRef.current])

  const rules = makeRules(props)

  const isCheckbox = field.type === "checkbox"
  const InputComponent = fieldTypes[field.type]
  const input = InputComponent ? (
    <>
      {error && (
        <div className="callout alert small" id={`${id}-error`} role="alert">
          <strong>
            {global.error.replace("NUMBER", error.number)}
            {error.message}
          </strong>
        </div>
      )}
      <InputComponent rules={rules} {...props} />
    </>
  ) : (
    <>TODO: {field.type}</>
  )
  const isFieldset =
    // fieldset complex (i.e. non-simple) types
    (![GenericInput, DateInput, Select, CheckBox].includes(InputComponent) ||
      // or if legend is explicitly specified
      text.legend) &&
    // but not if it's an only child
    !single
  const Tag = isFieldset ? "fieldset" : "div"
  const tagProps = {
    className: cx(
      styles.inputContainer,
      rules.required.value && !text.legend && styles.required,
      text.abbr && styles.hasAbbr,
      className
    ),
    style,
  }
  if (isFieldset && error) tagProps["aria-invalid"] = true

  if (displayField) {
    return (
      <Tag {...tagProps} ref={tagRef}>
        {text.legend && (
          <legend
            className={cx(
              rules.required.value && styles.required,
              styles.legend
            )}
            id={`${id}-${!text.label ? "label" : "legend"}`}
          >
            {text.legend}
          </legend>
        )}
        {text.abbr && (
          <aside role="note" className={styles.abbr} ref={asideRef}>
            {text.abbr}
          </aside>
        )}
        {text.description && (
          <aside
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: text.description }}
          />
        )}
        {text.label ? (
          <label
            className={cx(
              isCheckbox && styles.checkbox,
              styles.desc,
              styles.label
            )}
            id={`${id}-label`}
            htmlFor={id}
          >
            {isCheckbox && input}
            {/* markdown support for labels - styling might break with certain elements */}
            <ReactMarkdown
              className={styles.label_markdown}
              source={text.label}
              linkTarget="_blank"
            />
            {
              // help text inside of label...
              text.help && (
                <p className={cx(styles.helpText, isCheckbox && styles.inline)}>
                  {text.help}
                </p>
              )
            }
          </label>
        ) : (
          // ...or outside if label does not exist
          text.help && (
            <p className={cx(styles.helpText, isCheckbox && styles.inline)}>
              {text.help}
            </p>
          )
        )}
        {!isCheckbox && input}
      </Tag>
    )
  } else {
    return <></>
  }
}

export default InputContainer
