import React, { useState, useContext } from "react"
// eslint-disable-next-line import/no-cycle
import InputContainer from "./InputContainer"
import FormContext from "../../contexts/FormContext"

const UnderRepresented = ({ id, field, text }) => {
  const { clearErrors } = useContext(FormContext)
  const [showSpecialOptions, setShowSpecialOptions] = useState({})

  // on change, the checkboxes check to see if they trigger a special option
  const updateSpecialOptionsVisibility = (evt, op) => {
    setShowSpecialOptions({
      ...showSpecialOptions,
      [op]: evt.currentTarget.checked,
    })
    // clear errors as fields are hidden
    if (!evt.currentTarget.checked) {
      clearErrors(op)
    }
  }

  // returns an array of all the special option fields
  const specialOptions = field.specialOptions.map(option => {
    if (!text.specialOptions[option.id])
      return `Missing i18n: ${id}/${option.id}`
    return (
      showSpecialOptions[option.id] && (
        <InputContainer
          key={option.id}
          id={`${id}-field-${option.id}`}
          field={option}
          text={text.specialOptions[option.id]}
        />
      )
    )
  })

  return (
    <>
      <InputContainer
        id={id}
        field={field.groups}
        text={text.groups}
        onChange={updateSpecialOptionsVisibility}
      />
      {specialOptions}
    </>
  )
}

export default UnderRepresented
