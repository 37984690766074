import React, { useState, useContext, useEffect } from "react"
// eslint-disable-next-line import/no-cycle
import InputContainer from "./InputContainer"
import FormContext from "../../contexts/FormContext"

const Timing = ({ id, field, text }) => {
  const { clearErrors } = useContext(FormContext)
  const [idk, setIdk] = useState(false)

  useEffect(() => {
    clearErrors(!idk ? [field.start.id, field.end.id] : field.month.id)
  }, [idk])

  return (
    <>
      {!idk ? (
        <>
          <InputContainer
            id={`${id}-start`}
            field={field.start}
            text={text.start}
          />
          <InputContainer id={`${id}-end`} field={field.end} text={text.end} />
        </>
      ) : (
        <InputContainer
          id={`${id}-month`}
          field={field.month}
          text={text.month}
        />
      )}
      <InputContainer
        id={`${id}-idk`}
        field={field.timelineHelp}
        text={text.timelineHelp}
        onChange={e => setIdk(e.currentTarget.checked)}
      />
    </>
  )
}

export default Timing
